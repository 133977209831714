<template lang="pug">
    section.content
        div.container-fluid
            div.row
                div.col-md-6
                    div.card.card-primary
                        div.card-header
                            h3.card-title Crear un Examen
                        form(role="form")
                            div.card-body
                                div.form-group
                                    label(for="sCourse") Nombre del Examen
                                    input.form-control(type="text" id="sCourse" v-model="model.sExamName" placeholder="Ingrese el nombre del curso")

                                div.form-group
                                    label(for="zipFile") Zip con los proyectos Active Presenter
                                    div.input-group
                                        div.custom-file
                                            input.custom-file-input(type="file" ref="file" id="zipFile" v-on:change="handleFileUpload()")
                                            label.custom-file-label(for="zipFile" id="zipLabel") Choose file
                               
                                div.form-group
                                    label(for="BackImage") Imagen de fondo para Certificados
                                    div.input-group
                                        div.custom-file
                                            input.custom-file-input(type="file" ref="file2" id="BackImage" v-on:change="handleFileUpload2()")
                                            label.custom-file-label(for="BackImage" id="backLabel") Choose file

                                div.form-group
                                    label(for="BadgeOn") Imagen de Insignia Activa
                                    div.input-group
                                        div.custom-file
                                            input.custom-file-input(type="file" ref="file3" id="BadgeOn" v-on:change="handleFileUpload3()")
                                            label.custom-file-label(for="BadgeOn" id="badgeOnLabel") Choose file

                                div.form-group
                                    label(for="BadgeOff") Imagen de Insignia Inactiva
                                    div.input-group
                                        div.custom-file
                                            input.custom-file-input(type="file" ref="file4" id="BadgeOff" v-on:change="handleFileUpload4()")
                                            label.custom-file-label(for="BadgeOff" id="badgeOffLabel") Choose file

                                div.form-group
                                    label(for="sTime") Tiempo para contestar (en minutos)
                                    input.form-control(type="number" id="sTime" v-model="model.iTimeToAnswer" placeholder="Tiempo para reolver el cuestionario")
                                div.form-group
                                    label(for="sAprobePercent") Procentaje de Aprobación
                                    input.form-control(type="text" id="sAprobePercent" v-model="model.iPassPercent" placeholder="Procentaje de aprobación")
                            div.card-footer
                                button.btn.btn-primary(type="button" @click="Save") Guardar
                                router-link.btn.btn-primary(to="/content/contenthome" ) Regresar



</template>
<script>
/* eslint-disable */
export default {
    name: 'CreateCourse',
    components: {
    },    
    data() {
        return {
            file: '',
            file2: '',
            file3: '',
            file4: '',
            model: {
                sExamName: '',
                sImagePath: '',
                sBadgeOn: '',
                sBadgeOff: '',
                iTimeToAnswer: 0,
                iPassPercent:0,
                examPaths: [],
                sParentPath: ''
            }
        }
    },
    methods: {
        async Save() {

            this.openLoading('Procesando Preguntas de Active Presenter ...');
            let formData = new FormData();
            let dir = []
            formData.append('file', this.file);            
            await axios.post('https://sistema.etciberoamerica.com/api/upload-file-insigne/exam', formData, { headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzQwODNjZmU4NzU3ZWMyY2MwZTA0MzNiM2U5MTQ3Y2U2ZDg0ZGVjMjFhNWU2Mzg4ZjAyNmFlNWQ0MWQ2YzcwNDZkODgwZDM4OWExMTUzNGQiLCJpYXQiOjE2NTE2NzY1NzUsIm5iZiI6MTY1MTY3NjU3NSwiZXhwIjoxNjgzMjEyNTc1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.7H7LbxJ6F2RMoD2kmuPglpPNReTGvwmVxzP7ThZ8iTjbO3_EG8dkpbgMLSC_p3Ve8bEVCKh77U8mrH37YdN7Ff4dS3_zMs0LYAA2739tFK4jij8l0g5kE0tSpnNgXWiEhIrImTn4IfFJ-DuCLlRPqBFK1_lX4liRooWbDUbHEhJ2979xhee4Y26w1t8luGMBRz5fkEL0dkfrCKw50m6Ov6GPqV59mmmf75rnEGGwm5SiureRDrtpzQtc7-cTBd9RNlV2Jvds3x4ALaVS2k5PiSP-YNrWXxXiRLPlORZ-9KugW_FV8aMdEuBf81tlYNA9N_QRdynnyiB7GGg2LMnh61yHSzwrSwb1KCM4dXy1RDLyguxF6BwJHvOyJ8JpSfjAU74W_XyCis-BCrk9IXGbc43g53XdvLFHeWFAjeB1nGceHHLP3HoJENEJnFJYZqZoz-Cu-yfA3G-P5euiz5E6lnmZqcZhxx-YSOtQCR6yaEwLPo4sGiZcjbxiumKT5NSCb7lC39nCbmW0DL_mc_2YrRp5cFYkvqn5Mu0BddgCrv8SkagqR_Smmd_2G3TlcNAIOf5jdVPs_hbLRPTw4g09GIt8xSjjdgGLDI7ev27c7UyNSlIgcTUsO2EBafJDgIhZE7LAH9ABFOukcA-A8fMEhDP-eAc2X59ECtX1CpPsLzE' 
            } })
            .then( (response) => {
                if (response.data.status == true) {
                    for (let index = 0; index < response.data.directories.length; index++) {
                        let directory = {
                            'sProjectPath': '../../../assets/' + response.data.parentFolder + '/' + response.data.directories[index] + '/practice.html',
                            'sFolderName': response.data.directories[index]
                        }
                        dir.push(directory)
                    }
                    this.model.examPaths = dir
                    this.model.sParentFolder = response.data.parentFolder
                }
            } )
            .catch( (error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
            Swal.close();


            this.openLoading('Procesando Imagen de fondo de Certificados ...');
            let formCert = new FormData();
            formCert.append('file', this.file2);
            await axios.post('https://sistema.etciberoamerica.com/api/upload-file-insigne/background', formCert, { headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzQwODNjZmU4NzU3ZWMyY2MwZTA0MzNiM2U5MTQ3Y2U2ZDg0ZGVjMjFhNWU2Mzg4ZjAyNmFlNWQ0MWQ2YzcwNDZkODgwZDM4OWExMTUzNGQiLCJpYXQiOjE2NTE2NzY1NzUsIm5iZiI6MTY1MTY3NjU3NSwiZXhwIjoxNjgzMjEyNTc1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.7H7LbxJ6F2RMoD2kmuPglpPNReTGvwmVxzP7ThZ8iTjbO3_EG8dkpbgMLSC_p3Ve8bEVCKh77U8mrH37YdN7Ff4dS3_zMs0LYAA2739tFK4jij8l0g5kE0tSpnNgXWiEhIrImTn4IfFJ-DuCLlRPqBFK1_lX4liRooWbDUbHEhJ2979xhee4Y26w1t8luGMBRz5fkEL0dkfrCKw50m6Ov6GPqV59mmmf75rnEGGwm5SiureRDrtpzQtc7-cTBd9RNlV2Jvds3x4ALaVS2k5PiSP-YNrWXxXiRLPlORZ-9KugW_FV8aMdEuBf81tlYNA9N_QRdynnyiB7GGg2LMnh61yHSzwrSwb1KCM4dXy1RDLyguxF6BwJHvOyJ8JpSfjAU74W_XyCis-BCrk9IXGbc43g53XdvLFHeWFAjeB1nGceHHLP3HoJENEJnFJYZqZoz-Cu-yfA3G-P5euiz5E6lnmZqcZhxx-YSOtQCR6yaEwLPo4sGiZcjbxiumKT5NSCb7lC39nCbmW0DL_mc_2YrRp5cFYkvqn5Mu0BddgCrv8SkagqR_Smmd_2G3TlcNAIOf5jdVPs_hbLRPTw4g09GIt8xSjjdgGLDI7ev27c7UyNSlIgcTUsO2EBafJDgIhZE7LAH9ABFOukcA-A8fMEhDP-eAc2X59ECtX1CpPsLzE' 
            } })
            .then( (response) => {
                Swal.close();
                if (response.data.status == true) {
                    this.model.sImagePath = this.file2.name
                    Swal.fire('Imagen de Fondo Procesada Correctamente!!')
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message,
                        footer: ''
                    })
                }
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )


            this.openLoading('Procesando Insignia Activa ...');
            let formBadge1 = new FormData();
            formBadge1.append('file', this.file3);
            await axios.post('https://sistema.etciberoamerica.com/api/upload-file-insigne/badge', formBadge1, { headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzQwODNjZmU4NzU3ZWMyY2MwZTA0MzNiM2U5MTQ3Y2U2ZDg0ZGVjMjFhNWU2Mzg4ZjAyNmFlNWQ0MWQ2YzcwNDZkODgwZDM4OWExMTUzNGQiLCJpYXQiOjE2NTE2NzY1NzUsIm5iZiI6MTY1MTY3NjU3NSwiZXhwIjoxNjgzMjEyNTc1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.7H7LbxJ6F2RMoD2kmuPglpPNReTGvwmVxzP7ThZ8iTjbO3_EG8dkpbgMLSC_p3Ve8bEVCKh77U8mrH37YdN7Ff4dS3_zMs0LYAA2739tFK4jij8l0g5kE0tSpnNgXWiEhIrImTn4IfFJ-DuCLlRPqBFK1_lX4liRooWbDUbHEhJ2979xhee4Y26w1t8luGMBRz5fkEL0dkfrCKw50m6Ov6GPqV59mmmf75rnEGGwm5SiureRDrtpzQtc7-cTBd9RNlV2Jvds3x4ALaVS2k5PiSP-YNrWXxXiRLPlORZ-9KugW_FV8aMdEuBf81tlYNA9N_QRdynnyiB7GGg2LMnh61yHSzwrSwb1KCM4dXy1RDLyguxF6BwJHvOyJ8JpSfjAU74W_XyCis-BCrk9IXGbc43g53XdvLFHeWFAjeB1nGceHHLP3HoJENEJnFJYZqZoz-Cu-yfA3G-P5euiz5E6lnmZqcZhxx-YSOtQCR6yaEwLPo4sGiZcjbxiumKT5NSCb7lC39nCbmW0DL_mc_2YrRp5cFYkvqn5Mu0BddgCrv8SkagqR_Smmd_2G3TlcNAIOf5jdVPs_hbLRPTw4g09GIt8xSjjdgGLDI7ev27c7UyNSlIgcTUsO2EBafJDgIhZE7LAH9ABFOukcA-A8fMEhDP-eAc2X59ECtX1CpPsLzE' 
            } })
            .then( (response) => {
                Swal.close();
                if (response.data.status == true) {
                    this.model.sBadgeOn = '/assets/img2/insignias/' + this.file3.name
                    Swal.fire('Imagen de Insignia Activa Procesada Correctamente!!')
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message,
                        footer: ''
                    })
                }
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )

            this.openLoading('Procesando Insignia Inactiva ...');
            let formBadge2 = new FormData();
            formBadge2.append('file', this.file4);
            await axios.post('https://sistema.etciberoamerica.com/api/upload-file-insigne/badge', formBadge2, { headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzQwODNjZmU4NzU3ZWMyY2MwZTA0MzNiM2U5MTQ3Y2U2ZDg0ZGVjMjFhNWU2Mzg4ZjAyNmFlNWQ0MWQ2YzcwNDZkODgwZDM4OWExMTUzNGQiLCJpYXQiOjE2NTE2NzY1NzUsIm5iZiI6MTY1MTY3NjU3NSwiZXhwIjoxNjgzMjEyNTc1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.7H7LbxJ6F2RMoD2kmuPglpPNReTGvwmVxzP7ThZ8iTjbO3_EG8dkpbgMLSC_p3Ve8bEVCKh77U8mrH37YdN7Ff4dS3_zMs0LYAA2739tFK4jij8l0g5kE0tSpnNgXWiEhIrImTn4IfFJ-DuCLlRPqBFK1_lX4liRooWbDUbHEhJ2979xhee4Y26w1t8luGMBRz5fkEL0dkfrCKw50m6Ov6GPqV59mmmf75rnEGGwm5SiureRDrtpzQtc7-cTBd9RNlV2Jvds3x4ALaVS2k5PiSP-YNrWXxXiRLPlORZ-9KugW_FV8aMdEuBf81tlYNA9N_QRdynnyiB7GGg2LMnh61yHSzwrSwb1KCM4dXy1RDLyguxF6BwJHvOyJ8JpSfjAU74W_XyCis-BCrk9IXGbc43g53XdvLFHeWFAjeB1nGceHHLP3HoJENEJnFJYZqZoz-Cu-yfA3G-P5euiz5E6lnmZqcZhxx-YSOtQCR6yaEwLPo4sGiZcjbxiumKT5NSCb7lC39nCbmW0DL_mc_2YrRp5cFYkvqn5Mu0BddgCrv8SkagqR_Smmd_2G3TlcNAIOf5jdVPs_hbLRPTw4g09GIt8xSjjdgGLDI7ev27c7UyNSlIgcTUsO2EBafJDgIhZE7LAH9ABFOukcA-A8fMEhDP-eAc2X59ECtX1CpPsLzE' 
            } })
            .then( (response) => {
                Swal.close();
                console.log('/assets/img2/insignias/' + this.file4.name)
                if (response.data.status == true) {
                    this.model.sBadgeOff = '/assets/img2/insignias/' + this.file4.name
                    Swal.fire('Imagen de Insignia Inactiva Procesada Correctamente!!')
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message,
                        footer: ''
                    })
                }
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )            
            


            //Aqui se haria la llamada al end point del server linux donde esta la vista de este sistema
            //se puede proporcionar el archivo ZIP en Base64 para ser mandado al end point como request  y 
            //que este sea manipulado en el backend de laravel (se solicitará al usuario una estructura especifica de ese zip)
            //la ruta donde se debe guardar es ~/assets/Exams y se requiere como respuesta un objeto con 2 propiedades
            // 1) La ruta donde se encuentra y 2) el nombre de la carpeta contenedora, ejemplo 
            //data: { {"path": "~/assets/CyberSafeCustom/Seguridad_Internet/test.html", "folderName": "Seguridad_Internet"} },
            //una vez que se haya obtenido la respuestas, se invocará otro end point que realizará el registro de esta infromacion
            //en la base de datos y se pueda cargar esa informacion en un combo del sistema

            //this.model.sImagePath = 'bg_Exam'
            //this.model.sBadgeOn = '/assets/img2/insignias/exam-badge-on.png'
            //this.model.sBadgeOff = '/assets/img2/insignias/exam-badge-off.png'
            //this.model.examPaths = dir //[{'sProjectPath': '../../../assets/CyberSafeCustom/Pregunta1/practice.html', 'sFolderName': 'Pregunta1'},{'sProjectPath': '../../../assets/CyberSafeCustom/Pregunta2/practice.html', 'sFolderName': 'Pregunta2'},{'sProjectPath': '../../../assets/CyberSafeCustom/Pregunta3/practice.html', 'sFolderName': 'Pregunta3'},{'sProjectPath': '../../../assets/CyberSafeCustom/Pregunta4/practice.html', 'sFolderName': 'Pregunta4'},{'sProjectPath': '../../../assets/CyberSafeCustom/Pregunta5/practice.html', 'sFolderName': 'Pregunta5'},{'sProjectPath': '../../../assets/CyberSafeCustom/Pregunta6/practice.html', 'sFolderName': 'Pregunta6'},]

            await axios.post(apiURL + 'Exam/Create', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire('Datos Guardados correctamente')
                router.push('/content/Exams')
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
            if (this.file.type.split("/").pop() != 'x-zip-compressed') {
                Swal.fire('Tipo de archivo inválido, por favor elija un archivo .zip con la estructura solicitada')
                return
            }
            document.getElementById("zipLabel").innerHTML = this.$refs.file.files[0].name
        },
        handleFileUpload2(){
            this.file2 = this.$refs.file2.files[0];
            if (this.file2.type.split("/").pop() != 'png') {
                Swal.fire('Tipo de archivo inválido, por favor elija un archivo de imagen .png')
                return
            }
            document.getElementById("backLabel").innerHTML = this.$refs.file2.files[0].name
        },
        handleFileUpload3(){
            this.file3 = this.$refs.file3.files[0];
            if (this.file3.type.split("/").pop() != 'png') {
                Swal.fire('Tipo de archivo inválido, por favor elija un archivo de imagen .png')
                return
            }            
            document.getElementById("badgeOnLabel").innerHTML = this.$refs.file3.files[0].name
        },
        handleFileUpload4(){
            this.file4 = this.$refs.file4.files[0];
            if (this.file4.type.split("/").pop() != 'png') {
                Swal.fire('Tipo de archivo inválido, por favor elija un archivo de imagen .png')
                return
            }            
            document.getElementById("badgeOffLabel").innerHTML = this.$refs.file4.files[0].name
        },        
    },
    mounted() {

    }    
        
}
</script>